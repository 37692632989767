import React, { useState, PropsWithChildren } from 'react'

export type PinnedButtonContextType = {
  isVisible: boolean
  setIsVisible: (set: boolean) => void
}

export const PinnedButtonContext =
  React.createContext<PinnedButtonContextType | null>(null)

type Props = {
  children: React.ReactNode
}

export const PinnedButtonContextProvider: React.FC<
  PropsWithChildren<Props>
> = ({ children }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  return (
    <PinnedButtonContext.Provider
      value={{
        isVisible,
        setIsVisible,
      }}
    >
      {children}
    </PinnedButtonContext.Provider>
  )
}
