export const LANGUAGE_MAP = {
  US: "en-US",
  IN: "en-IN",
  CA: "en-CA",
  FC: "fr-CA",
  KR: "ko-KR",
  JP: "ja-JP",
  PL: "pl-PL",
  NL: "nl-NL",
  ES: "es-ES",
  GE: "de-DE",
  LA: "es",
  UK: "en-GB",
} as const;
