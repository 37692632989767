import React, { useState, PropsWithChildren, useEffect } from 'react'

export type HeaderContextType = {
  isVisible: boolean
  setIsVisible: (set: boolean) => void
  position: string
  setPosition: (set: string) => void
  theme: string
  setTheme: (set: string) => void
  isClear: boolean
  setIsClear: (set: boolean) => void
  isClearDark: boolean
  setIsClearDark: (set: boolean) => void
  hasProgressBar: boolean
  setHasProgressBar: (set: boolean) => void
  showLocaleSwitcher: boolean
  setShowLocaleSwitcher: (set: boolean) => void
  showReferencesModal: boolean
  setShowReferencesModal: (set: boolean) => void
}

export const HeaderContext = React.createContext<HeaderContextType | null>(null)

type Props = {
  children: React.ReactNode
  initTransparent?: boolean
  initTheme?: string
}

export const HeaderContextProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  initTransparent,
  initTheme,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(true)
  const [position, setPosition] = useState<string>('fixed')
  const [theme, setTheme] = useState<string>(initTheme ?? 'light')
  const [isClear, setIsClear] = useState<boolean>(initTransparent ?? false)
  const [isClearDark, setIsClearDark] = useState<boolean>(false)
  const [hasProgressBar, setHasProgressBar] = useState<boolean>(false)
  const [showLocaleSwitcher, setShowLocaleSwitcher] = useState<boolean>(false)
  const [showReferencesModal, setShowReferencesModal] = useState<boolean>(false)

  useEffect(() => {
    setIsClear(initTransparent ?? false)
  }, [initTransparent])

  useEffect(() => {
    setTheme(initTheme ?? 'light')
  }, [initTheme])

  return (
    <HeaderContext.Provider
      value={{
        isVisible,
        setIsVisible,
        position,
        setPosition,
        theme,
        setTheme,
        isClear,
        setIsClear,
        isClearDark,
        setIsClearDark,
        hasProgressBar,
        setHasProgressBar,
        showLocaleSwitcher,
        setShowLocaleSwitcher,
        showReferencesModal,
        setShowReferencesModal,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}
