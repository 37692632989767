import { settings, locales, layouts } from '../config/settings'
import { Settings } from '../settings-types'

const getSettings = (layout: string, locale: string): Settings => {
  type LocalesKey = keyof typeof locales
  type LayoutsKey = keyof typeof layouts

  const getLayout = layouts[layout as LayoutsKey]

  type LayoutsLocaleKey = keyof typeof getLayout

  return {
    ...settings,
    ...(locales[locale as LocalesKey] ?? {}),
    ...getLayout[locale as LayoutsLocaleKey],
  }
}

export default getSettings
