import React, { useEffect } from 'react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { appWithTranslation } from 'next-i18next'
import { IntercomProvider } from 'react-use-intercom'
import TagManager from 'react-gtm-module'
import { HeaderContextProvider } from 'ui/context/header'
import { MastheadContextProvider } from 'ui/context/masthead'
import { PinnedButtonContextProvider } from 'ui/context/pinned-button'
import 'ui/css/global.css'
import AccessibeScript from 'ui/components/organisms/c-accessibe-script'
import OneTrustScript from 'ui/components/organisms/c-one-trust'
import { useSiteInfo } from 'shared'
import { useRouter } from 'next/router'

declare global {
  export interface Window {
    OneTrust: any
  }
}

let isGtmInitialized = false

const App = ({ Component, pageProps }: AppProps) => {
  const siteInfo = useSiteInfo()
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      window.OneTrust?.initializeCookiePolicyHtml()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    const gtmIdGE = process.env.NEXT_PUBLIC_DISCOVERICL_GMTD_ID_1_GERMAN
    const gtmId2GE = process.env.NEXT_PUBLIC_DISCOVERICL_GMTD_ID_1_GERMAN
    if (siteInfo.isGE && (gtmIdGE || gtmId2GE) && !isGtmInitialized) {
      gtmIdGE && TagManager.initialize({ gtmId: gtmIdGE })
      gtmId2GE && TagManager.initialize({ gtmId: gtmId2GE })
      isGtmInitialized = true
    }
    if (process.env.NODE_ENV === 'production') {
      const gtmId1 = process.env.NEXT_PUBLIC_DISCOVERICL_GMTD_ID_1
      const gtmId2 = process.env.NEXT_PUBLIC_DISCOVERICL_GMTD_ID_2
      gtmId1 && TagManager.initialize({ gtmId: gtmId1 })
      gtmId2 && TagManager.initialize({ gtmId: gtmId2 })
    }
  }, [])

  return (
    <HeaderContextProvider
      initTransparent={pageProps.transparentHeader ?? false}
      initTheme={pageProps.headerTheme ?? 'light'}
    >
      <MastheadContextProvider>
        <PinnedButtonContextProvider>
          <IntercomProvider
            appId={
              siteInfo.isUS ? process.env.NEXT_PUBLIC_INTERCOM_ID ?? '' : ''
            }
            autoBoot
          >
            <Head>
              <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>
            </Head>
            <Component {...pageProps} />
            {process.env.VERCEL_ENV === 'production' && (
              <Script
                async
                defer
                src="https://static.cloudflareinsights.com/beacon.min.js?token=fef3278bbc424edea74411c69ce520e8"
              />
            )}
            {(siteInfo.isUS || siteInfo.isCA || siteInfo.isFC) && (
              <AccessibeScript />
            )}
            <OneTrustScript />
          </IntercomProvider>
        </PinnedButtonContextProvider>
      </MastheadContextProvider>
    </HeaderContextProvider>
  )
}

export default appWithTranslation(App)
