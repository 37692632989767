import mapbox from "../helpers/mapbox";
import useSettings from "./use-settings";
import { useSiteInfo } from "./use-site-info";

export const useMapbox = () => {
  const settings = useSettings();
  const siteInfo = useSiteInfo();

  const mapboxInstance = mapbox(settings, siteInfo);

  return mapboxInstance;
};

export default useMapbox;
