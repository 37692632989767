import { useScript } from 'shared/hooks/use-script'
import Script from 'next/script'

const AccessibeScript = () => {

  const [state] = useScript({
    src: 'https://acsbapp.com/apps/app/dist/js/app.js',
    position: 'body',
  })

  return (
    <>
      {state === "active" && (
        <Script>
          {`acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'left', leadColor : '#146ff8', triggerColor : '#146ff8', triggerRadius : '50%', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'medium', triggerOffsetX : 20, triggerOffsetY : 85, mobile : { triggerSize : 'small', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 70, triggerRadius : '50%' } })`}
        </Script>
      )}
    </>
  )
}

export default AccessibeScript
