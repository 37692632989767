/* eslint-disable @next/next/no-sync-scripts */

import Script from "next/script"

const OneTrustScript = () => {
	switch(process.env.VERCEL_ENV) {
		case "production":
			return (
				<>
					<Script 
						async
						strategy="lazyOnload"
						src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
						data-domain-script="8ab3bcbc-88cc-4eee-9202-7d23c4aa86c4"
					/>
				</>
			)
		default:
			return (
				<>
					<Script 
						async
						strategy="lazyOnload"
						src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
						data-domain-script="8ab3bcbc-88cc-4eee-9202-7d23c4aa86c4-test"
					/>
					
				</>
			)
	}
}

export default OneTrustScript
