import React, { useState, PropsWithChildren } from 'react'

export type MastheadContextType = {
  isIntersecting: boolean
  setIsIntersecting: (set: boolean) => void
}

export const MastheadContext = React.createContext<MastheadContextType | null>(
  null
)

type Props = {
  children: React.ReactNode
}

export const MastheadContextProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false)

  return (
    <MastheadContext.Provider
      value={{
        isIntersecting,
        setIsIntersecting,
      }}
    >
      {children}
    </MastheadContext.Provider>
  )
}
