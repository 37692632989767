import { useEffect, useState } from 'react'

export type UseScriptValue = [
  state: ScriptStatus,
  setSource: (src?: string) => void
]

export type ScriptStatus = 'unloaded' | 'loading' | 'active' | 'error'
export const useScript = (params: {
  src?: string
  async?: boolean
  position?: 'head' | 'body'
  beforeFirstChild?: boolean
  enabled?: boolean
  insideChildNode?: ChildNode | null
}): UseScriptValue => {
  const {
    src,
    async = true,
    position = 'body',
    beforeFirstChild = false,
    enabled = true,
    insideChildNode,
  } = params
  const [source, setSource] = useState<string | undefined>(src)
  const [scriptState, setScriptState] = useState<ScriptStatus>(
    src ? 'loading' : 'unloaded'
  )

  // on state change
  useEffect(() => {
    if (!enabled) {
      return
    }

    let script = document.querySelector(`script[src="${source}"]`) as
      | HTMLScriptElement
      | undefined

    if (!source) {
      setScriptState('unloaded')
    } else if (source && !script) {
      // if script is not in DOM yet, add it
      script = document.createElement('script')
      script.src = source
      script.async = async

      // callback on script load/error to update the hooks state
      const setStateCallback = (event: Event) => {
        setScriptState(
          (event.type as keyof HTMLElementEventMap) === 'load'
            ? 'active'
            : 'error'
        )
      }

      script.addEventListener('load', setStateCallback)
      script.addEventListener('error', setStateCallback)
      // attach the script to the head/body DOM

      if (insideChildNode !== undefined) {
        insideChildNode?.appendChild(script)
        return
      }

      if (beforeFirstChild) {
        document?.[position].insertBefore(
          script,
          document?.[position].childNodes[0]
        )
      } else {
        document?.[position].appendChild(script)
      }
    }

    return (): void => {
      if (script) script.remove()
    }
  }, [insideChildNode, enabled, position, beforeFirstChild, source, async])

  return [scriptState, (sourceUrl?: string) => setSource(sourceUrl)]
}