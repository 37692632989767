import { Settings } from "../settings-types";
import getSettings from "../helpers/get-settings";
import { useSiteInfo } from "./use-site-info";

export const useSettings = (): Settings => {
  const siteInfo = useSiteInfo();

  return getSettings(siteInfo?.layout ?? "icl", siteInfo?.iso ?? "en-US");
};

export default useSettings;
